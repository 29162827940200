.c-card__pic__labelframe {
  position: absolute;
  top: 8px;
  left: 0;
}
.c-card__pic__labelframe > div + div {
  margin-top: 4px;
}
.c-card__pic__labelframe__recommend {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-card__pic__labelframe__recommend div {
  width: 32px;
  height: 32px;
  border: 1px solid #fff;
  border-radius: 100%;
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}
.c-card__pic__labelframe__recommend div {
  background: #2cb582;
}
.c-card__pic__labelframe__recommend div img {
  width: 16px;
  height: 16px;
}
.c-card__pic__labelframe__recommend p {
  width: 132px;
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  color: #ff0045;
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 40px;
  margin-left: -36px;
  position: relative;
}
.c-card__pic__labelframe__recommend p::before,
.c-card__pic__labelframe__recommend p::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 8px 0 0;
  border-color: #fff transparent transparent transparent;
  position: absolute;
  top: 0;
  right: -8px;
}
.c-card__pic__labelframe__recommend p::after {
  transform: scale(1, -1);
}
.c-card__bottom__original__price {
  text-align: right;
  color: #888;
  text-decoration: line-through;
}
.c-card__pic__labelframe__promotion {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background: #ff0045;
  padding: 0 8px;
  position: relative;
  display: inline-block;
}
.c-card__pic__labelframe__promotion::before,
.c-card__pic__labelframe__promotion::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 8px 0 0;
  border-color: #ff0045 transparent transparent transparent;
  position: absolute;
  top: 0;
  right: -8px;
}
.c-card__pic__labelframe__promotion::after {
  transform: scale(1, -1);
}
